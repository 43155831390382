import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal
} from '@angular/core';
import { Dictionary } from '~shared/models/dictionary';
import { SafeTKDB } from '~indexedDB';
import { FacilityState } from '~core/states/facility/facility.state';
import { NetworkState } from '~offline/states/network-state/network.state';
import { filter } from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-nav-group',
  templateUrl: './nav-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavGroupComponent implements OnInit {

  private cdr = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private facilityState = inject(FacilityState);
  private networkState = inject(NetworkState);
  private indexedDB = inject(SafeTKDB);

  @Input() group: Dictionary<any>;

  @Input() isActive: boolean;

  @Input() isOpen: boolean;

  @Output() groupClick = new EventEmitter<Dictionary<any>>();

  links = signal([]);

  offlineModeEnabled$ = combineLatest([ this.facilityState.moduleSettings$, this.networkState.isOffline$ ]).pipe(
    map(([ moduleSettings, isOffline ]) => !!moduleSettings?.OfflineMode && isOffline)
  );

  ngOnInit(): void {
    this.offlineModeEnabled$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(offlineEnabled => {
        if (offlineEnabled) {
          this.links.set(filter(this.group?.links, link => {
            const name = link.name.toLowerCase();
            return name.includes('create') && !name.includes('standard');
          }));
        } else {
          this.links.set(this.group?.links);
        }
      });
  }
}
