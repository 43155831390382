<div
  (click)="groupClick.emit(group)"
  class="nav-block p-2 flex flex-row justify-start items-center w-full"
  [class.selected]="isActive"
>
  <i [class]="(group?.icon?.length ? group.icon : 'fas fa-circle-notch') + ' w-[25px]'"></i>

  <span class="nav-block-title p-1 grow">
    {{ group?.name }}
  </span>
  <i [class]="(isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down') + ' w-[25px]'"></i>
</div>
@if (isOpen) {
  <div class="flex flex-row flex-wrap">
    @if (offlineModeEnabled$ | async) {
      <div class="flex flex-row flex-wrap w-full border-b border-gray-500 bg-red-50 hover:bg-red-100 py-2">
        <a
          [routerLink]="['offline', 'permit', group.itemType, 'index']"
          routerLinkActive="text-stilRed-100 bg-gray-300"
          class="flex flex-row justify-start items-center w-full text-gray-900 hover:text-black hover:no-underline"
        >
          <mat-icon class="text-base ml-[25px]">public_off</mat-icon>
          <span class="nav-link-title text-xs">
            {{ [group?.itemType, 'LEFT_NAV', 'Permit Index v2'] | translate }}
          </span>
        </a>
      </div>
    }

    @for (link of links(); track link.ID) {
      <app-nav-link [link]="link"></app-nav-link>
    }
  </div>
}
